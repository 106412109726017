<template>
  <div class="content">
    <section class="page-header news">
      <div class="page-header-bg"></div>
      <div class="page-header-bg-overly"></div>
      <div class="container">
        <div class="page-header__inner">
          <h2>璐付资讯</h2>
        </div>
      </div>
    </section>
    <!--News Page Start-->
    <section class="news-one news-page">
      <div class="container">
        <div class="news-one__hdtitle">新闻资讯</div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms" v-for="(item, index) in 10" :key="index">
            <!--News One Single-->
            <div class="news-one__single">
              <div class="news-one__img">
                <img src="../../assets/picture/news-one-img-1.jpg" alt="" />
                <a href="javascript:void(0)" @click="$router.push({ name: 'details' })">
                  <span class="news-one__plus"></span>
                </a>
                <div class="news-one__date-box">
                  <p>
                    20 <br />
                    8月
                  </p>
                </div>
              </div>
              <div class="news-one__content">
                <!-- <ul class="list-unstyled news-one__meta">
                  <li>
                    <a href="#"><i class="far fa-user-circle"></i>来源:璐付科技</a>
                  </li>
                </ul> -->
                <h3 class="news-one__title">
                  <a href="javascript:void(0)" @click="$router.push({ name: 'details' })">
                    短信接口平台如何选择?哪些特点是平台必备的?
                  </a>
                </h3>
                <p class="news-one__text">
                  对于想要使用短信接口平台发送短信的企业来说，选择短信发送接口平台是一个非常麻烦的问题..
                </p>
                <!-- <div class="news-one__read-more">
                  <a href="details.vue" class="news-one__read-more-btn">查看</a>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-xl-12 text-center">
            <!-- <a href="#" class="thm-btn">查看更多</a> -->
          </div>
          <!-- /.col-xl-12 -->
        </div>
      </div>
    </section>
    <!--News Page End-->
  </div>
</template>

<script>
export default {};
</script>

<style scoped src="../../assets/cmomon/bootstrap-select.min.css"></style>
<style scoped src="../../assets/cmomon/bootstrap.min.css"></style>
<style scoped src="../../assets/cmomon/owl.carousel.min.css"></style>
<style scoped src="../../assets/cmomon/style.css"></style>
<style scoped src="../../assets/cmomon/izeetak-responsive.css"></style>
<style scoped src="../../assets/cmomon/izeetak.css"></style>
